import axios from "axios";

const base = axios.create({
    baseURL:"https://proquality-transportation-2-c714b4dc37ba.herokuapp.com",
})
export class TelegramAPI {
    async Posting(){
        //const res = await base.post("/api/TMS/StartSession").then(u =>u.data)
        //return res
    }
    async GetAccount(){
        const res = await base.get("api/TMS/GetAccountFoldersCount").then(u =>u.data)
        return res
    }
    async GetReportFileContent(data:string){
        const res = await base.get(`api/TMS/GetReportFileContent/${data}`).then(u =>u.data)
        console.log(res)

        return res
    }
    async ReportDates(){
        const res = await base.get("api/TMS/GetReportDates").then(u=>u.data)
        return res
    }
}