import {createAsyncThunk, createReducer} from "@reduxjs/toolkit";
import {FbAPI} from "../API/FbAPI";

interface FbConfig{
    user:Array<string>,
    SelectCategory:Array<string>,
    TrelloBoardId:string,
    TrelloListId:string,
    Name:string,
    Description:string
}
interface InitialStateType{

}
const InitialState:InitialStateType={

}

export const PostingFb= createAsyncThunk<unknown,FbConfig>("POSTING-FACEBOOK",async (value,{dispatch})=>{
    const response = await new FbAPI().Posting()
    return response
})
export const Fb = createReducer(InitialState,{

})