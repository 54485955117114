import React from 'react'
import {Box, Button, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import {useAppDispatch} from "../../app/store";
import {PostingFb} from "../../app/Fb";


interface InitialValueType{
    user:Array<string>,
    SelectCategory:Array<string>,
    TrelloBoardId:string,
    TrelloListId:string,
    Name:string,
    Description:string
}

const InitialValues:InitialValueType={
    user:[],
    Description:'',
    Name:'',
    SelectCategory:[],
    TrelloBoardId:'',
    TrelloListId:'',
}
export const Fb = () => {
    const dispatch = useAppDispatch()
    const formik = useFormik({
        initialValues:InitialValues,
        onSubmit:(values)=>{
            dispatch(PostingFb(values))
        }
    })
    return (
        <Box className="my-10 text-[#333]">
            <Typography color='#666' variant='h4' sx={{ml:'35vw',my:'1.25rem'}}>
                Facebook
            </Typography>
            <div className='flex flex-col text-[#666] justify-center items-center ' >
                <form onSubmit={formik.handleSubmit} className='border max-w-[780px] min-h-[420px] flex flex-col gap-3 border-solid border-neutral-400 p-5 rounded'>
                    <Typography color='#666' variant='h5'>
                        Config
                    </Typography>
                    <div className='mt-3 flex flex-col gap-3'>
                        <div className='flex gap-3 w-[458px]'>
                            <div className='w-[49%]'>
                                <InputLabel id="user">Select User</InputLabel>
                                <Select name='user' onChange={formik.handleChange} id='user' size='small' fullWidth   variant="outlined">
                                    <MenuItem value=''> <em>None</em> </MenuItem>
                                </Select>
                            </div>
                            <div className='w-[49%]'>
                                <InputLabel id="category">Select Category</InputLabel>
                                <Select  name='SelectCategory' onChange={formik.handleChange} id='category' size='small' fullWidth   variant="outlined">
                                    <MenuItem value=''> <em>None</em> </MenuItem>
                                </Select>
                            </div>
                        </div>
                        <div className='flex gap-3  w-[458px]'>
                            <div className='w-[49%]'>
                                <InputLabel id="board-id">Trello board id</InputLabel>
                                <Select name='TrelloBoardId' onChange={formik.handleChange} id='board-id' size='small' fullWidth   variant="outlined">
                                    <MenuItem value=''> <em>None</em> </MenuItem>
                                </Select>
                            </div>
                            <div className='w-[49%]'>
                                <InputLabel id="list-id">Trello List id</InputLabel>
                                <Select name='TrelloListId' onChange={formik.handleChange} id='list-id' size='small' fullWidth   variant="outlined">
                                    <MenuItem value=''> <em>None</em> </MenuItem>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <Typography color='#666' variant='h5'>
                        Content
                    </Typography>
                    <div className='mt-3 flex flex-col  gap-3'>
                        <div className='flex gap-3 w-[458px]'>
                            <TextField name='Name' onChange={formik.handleChange} fullWidth size='small' label="Name" variant="outlined"/>
                        </div>
                        <div className='flex gap-3  w-[458px]'>
                            <TextField name='Description' onChange={formik.handleChange} fullWidth size='small' multiline rows={4} label="Description" variant="outlined"/>
                        </div>
                        <div className='flex gap-3  w-[458px]'>
                            <Button type='submit' variant="contained" fullWidth>
                                Send
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </Box>
    )
}
