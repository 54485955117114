import React from 'react';
import {Header} from "./componennts/Header/Header";
import {Route, Routes} from "react-router-dom";
import {Telegram} from "./componennts/Telegram/Telegram";
import {Reddit} from "./componennts/Reddit/Reddit";
import {Fb} from "./componennts/Fb/Fb";
import {Defalut} from "./componennts/Defalut/Defalut";

function App() {
  return (
    <div>
      <Header/>
        <Routes>
            <Route path={'/telegram'} element={<Telegram/>}/>
            <Route path={'/reddit'} element={<Reddit/>}/>
            <Route path={'/facebook'} element={<Fb/>}/>
            <Route path={'/'} element={<Defalut/>}/>
        </Routes>
    </div>
  );
}

export default App;
