import axios from "axios";

const base = axios.create({
    baseURL:''
})
export class FbAPI {
    async Posting(){
        const response = await base.post("").then(u=>u.data)
        return response
    }
}