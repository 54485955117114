import axios from "axios";
import {PostingRedditType} from "../app/Reddit";

const base = axios.create({
    baseURL:''
})
export class RedditAPI {
    async Posting(config:PostingRedditType){

        const res = await base.post("",config).then(u =>u.data)
        return res
    }
}