import {configureStore} from "@reduxjs/toolkit";
import { Telegram } from "./Telegram"
import { Reddit } from './Reddit'
import { Fb } from  './Fb'
import {useDispatch} from "react-redux";
export const store = configureStore({
    reducer:{
        Telegram,
        Reddit,
        Fb
    }
})

export const useAppDispatch = () => useDispatch<typeof store.dispatch>()
export type RootState = ReturnType<typeof store.getState>

