import React from 'react'
import {Box, Button, Input, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useAppDispatch} from "../../app/store";
import {PostingReddit} from "../../app/Reddit";

interface InitialValueType{
    file:string,
    SelectCategory:Array<string>,
    beatwenSteps:string,
    PostperUser:string,
    TrelloBoardId:string,
    TrelloListId:string,
    Name:string,
    Title:string,
    Description:string
}

const InitialValues:InitialValueType={
    beatwenSteps:'',
    file:'',
    Description:'',
    Name:'',
    PostperUser:'',
    SelectCategory:[],
    Title:'',
    TrelloBoardId:'',
    TrelloListId:'',
}

const ValidationShema = Yup.object().shape({
    PostperUser:Yup.number().required("Обов'язкове поле"),
    beatwenSteps:Yup.number().required("Обов'язкове поле")
})

export const Reddit = () => {
    const dispatch = useAppDispatch()
    const formik = useFormik({
        initialValues:InitialValues,
        onSubmit:(values)=>{
            dispatch(PostingReddit(values))
        },
        validationSchema:ValidationShema
    })
    return (
        <Box className="my-10 text-[#333]">
            <Typography color='#666' variant='h4' sx={{ml:'35vw',my:'1.25rem'}}>
                Reddit
            </Typography>
            <div className='flex flex-col text-[#666] justify-center items-center ' >
                <form onSubmit={formik.handleSubmit} className='border max-w-[780px] min-h-[420px] flex flex-col gap-3 border-solid border-neutral-400 p-5 rounded'>
                    <Typography color='#666' variant='h5'>
                        Config
                    </Typography>
                    <div className='mt-3 flex flex-col gap-3'>
                        <div className='flex gap-3 w-[458px]'>
                            <div className='w-[49%] mt-6'>
                                <Input name='file' onChange={formik.handleChange} type='file' fullWidth/>
                            </div>
                            <div className='w-[49%]'>
                                <InputLabel id="category-reddit">Select Category</InputLabel>
                                <Select name='SelectCategory' onChange={formik.handleChange}  id='category-reddit' size='small' fullWidth   variant="outlined">
                                    <MenuItem value=''> <em>None</em> </MenuItem>
                                </Select>
                            </div>
                        </div>
                        <div className='flex gap-3'>
                            <div>
                                <TextField {...formik.getFieldProps('beatwenSteps')}  size='small' label="sleep Between Posts" variant="outlined"/>
                                {formik.touched.beatwenSteps && formik.errors.beatwenSteps ? (
                                    <div className='text-red-600'>{formik.errors.beatwenSteps}</div>
                                ) : null}
                            </div>
                            <div>
                                <TextField {...formik.getFieldProps('PostperUser')}  size='small' label="Post per User" variant="outlined"/>
                                {formik.touched.PostperUser && formik.errors.PostperUser ? (
                                    <div className='text-red-600'>{formik.errors.PostperUser}</div>
                                ) : null}
                            </div>
                        </div>
                        <div className='flex gap-3  w-[458px]'>
                            <div className='w-[49%]'>
                                <InputLabel id="board-id">Trello board id</InputLabel>
                                <Select  name='TrelloBoardId' onChange={formik.handleChange}  id='board-id' size='small' fullWidth   variant="outlined">
                                    <MenuItem value=''> <em>None</em> </MenuItem>
                                </Select>
                            </div>
                            <div className='w-[49%]'>
                                <InputLabel id="list-id">Trello List id</InputLabel>
                                <Select  name='TrelloListId' onChange={formik.handleChange}  id='list-id' size='small' fullWidth   variant="outlined">
                                    <MenuItem value=''> <em>None</em> </MenuItem>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <Typography color='#666' variant='h5'>
                        Content
                    </Typography>
                    <div className='mt-3 flex flex-col  gap-3'>
                        <div className='flex gap-3'>
                            <TextField name='Name' onChange={formik.handleChange} size='small' label="Name" variant="outlined"/>
                            <TextField name='Title' onChange={formik.handleChange}  size='small' label="Title" variant="outlined"/>
                        </div>
                        <div className='flex gap-3  w-[458px]'>
                            <TextField fullWidth  name='Description' onChange={formik.handleChange}  size='small' multiline rows={4} label="Description" variant="outlined"/>
                        </div>
                        <div className='flex gap-3  w-[458px]'>
                            <Button type='submit'  variant="contained" fullWidth>
                                Send
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </Box>
    )
}
