import {createAction, createAsyncThunk, createReducer, PayloadAction} from "@reduxjs/toolkit";
import {TelegramAPI} from "../API/TelegramAPI";

export interface PostingConfig{
    isExtra?:boolean,
    AmountAccount:number,
    SessionTask:number
}

interface InitialStateProps{
    date:string
    dates:Array<string>
    Analytics:any
}
const InitialState:InitialStateProps={
    dates:[],
    date:"2023.10.25_14-38",
    Analytics:{}
}

export const PostingTelegram = createAsyncThunk<unknown,PostingConfig>("POSTING-TELEGRAM",async (value,{dispatch})=>{
    const response = await new TelegramAPI().Posting()
    return response
})

export const GetAccountDataTelegram = createAsyncThunk("GET-ACCOUNT-DATA-TELEGRAM",async (_,{dispatch})=>{
    const response = await new TelegramAPI().GetAccount()
    dispatch(SetAccountData(response))
})

export const GetReportFileContentDataTelegram = createAsyncThunk<unknown,string>("GET-ANALITYCS-DATA-TELEGRAM",async (value,{dispatch})=>{
    const response = await new TelegramAPI().GetReportFileContent(value)

    dispatch(SetAnalitycsData(response))
})
export const ReportDatesTelegram = createAsyncThunk("ANALITYCS-DATA-TELEGRAM",async (_,{dispatch})=>{
    const response = await new TelegramAPI().ReportDates()
    dispatch(SetAccountData(response))
})
export const SetCurrentDate = createAction<string>("SET-CURRENT-DATE")
const SetAccountData = createAction<Array<string>>("SET-ACCOUNT-DATA")
const SetAnalitycsData = createAction<any>("SET-ANALITYCS-DATA")

export const Telegram = createReducer(InitialState,{
    [SetAnalitycsData.type]:(state,action:PayloadAction<any>)=>{
        return{
            ...state,
            Analytics:action.payload
        }
    },
    [SetAccountData.type]:(state,action:PayloadAction<Array<string>>)=>{
        return{
            ...state,
            dates:action.payload
        }
    },
    [SetCurrentDate.type]:(state,action:PayloadAction<string>)=>{
        return{
            ...state,
            date:action.payload
        }
    }
})