import React from 'react'
import {NavLink} from "react-router-dom";

export const Header = () => {
    return (
        <div className='flex px-32 gap-16 items-center w-screen h-[80px] text-[24px] text-white font-bold bg-gradient-to-b from-blue-400 via-cyan-500 to-blue-600'>
            <NavLink to={'/telegram'}>
                Telegram
            </NavLink>
            <NavLink to={'/facebook'}>
                Facebook
            </NavLink>
            <NavLink to={'/reddit'}>
                Reddit
            </NavLink>
        </div>
    )
}
