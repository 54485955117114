import {createAsyncThunk, createReducer} from "@reduxjs/toolkit";
import {RedditAPI} from "../API/RedditAPI";

interface InitialStateType{

}
const InitialState:InitialStateType={

}
export interface PostingRedditType{
    file:string,
    SelectCategory:Array<string>,
    beatwenSteps:string,
    PostperUser:string,
    TrelloBoardId:string,
    TrelloListId:string,
    Name:string,
    Title:string,
    Description:string
}
export const PostingReddit = createAsyncThunk<unknown,PostingRedditType>("POSTING-REDDIT",async (config,{dispatch})=>{
    const response = await new RedditAPI().Posting(config)
    return response
})
export const Reddit = createReducer(InitialState,{

})