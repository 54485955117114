import React, {useEffect, useState} from 'react'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {Box, Switch, TextField} from "@mui/material";
import {useFormik} from "formik";
import * as Yup from "yup"
import {
    GetAccountDataTelegram,
    GetReportFileContentDataTelegram,
    PostingConfig,
    PostingTelegram,
    ReportDatesTelegram, SetCurrentDate
} from "../../app/Telegram";

import {RootState, useAppDispatch} from "../../app/store";
import {useSelector} from "react-redux";

const Accordion = styled((props: AccordionProps) => ( <MuiAccordion disableGutters elevation={0} square {...props} />))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));
const AccordionSummary = styled((props: AccordionSummaryProps) => (<MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props}/>))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const ValidationShema = Yup.object().shape({
    AccountsCount: Yup.number().required("Обов'язкове поле"),
    SesionTask: Yup.number().required("Обов'язкове поле")
})

interface InValuesType{
    AccountsCount:number,
    SessionTask:number
}

const InitialValues:InValuesType={
    AccountsCount:0,
    SessionTask:0
}

export const Telegram = () => {
    const { Analytics,date,dates } = useSelector((u:RootState)=>u.Telegram)
    const dispatch = useAppDispatch()
    const formik = useFormik({
        initialValues:InitialValues,
        onSubmit:(values)=>{
        },
        validationSchema:ValidationShema
    })
    useEffect( ()=>{
        dispatch(GetAccountDataTelegram())
        dispatch(ReportDatesTelegram())
    },[dispatch])
    useEffect(()=>{
        dispatch(GetReportFileContentDataTelegram(date))
    },[dispatch,date])

    const [expanded, setExpanded] = React.useState('panel1');
    const handleChange =(panel:any) => (event:any, newExpanded:any) => {
        setExpanded(newExpanded ? panel : false);
    };
    const [ DefalutData,setDefalutData ] = useState<PostingConfig>({SessionTask:0,AmountAccount:0,isExtra:false})
    const [ ExtraData,setExtraData ] = useState<PostingConfig>({SessionTask:0,AmountAccount:0,isExtra:true})
    useEffect(()=>{
        setDefalutData({
            isExtra:false,
            AmountAccount:formik.values.AccountsCount,
            SessionTask:formik.values.SessionTask
        })

        setExtraData({
            isExtra:true,
            AmountAccount:formik.values.AccountsCount,
            SessionTask:formik.values.SessionTask
        })

    },[formik.values])

    return (
        <Box className=' gap-6 p-5 flex flex-col  max-w-[1200px] min-h-screen mx-auto '>
            <div className='font-bold text-[22px] text-[#333]'>
                Telegram
            </div>
            <div className='flex p-5  border border-solid border-slate-300 min-h-[450px] rounded'>
                <div className='max-w-[560px] mx-10 my-5'>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Posting Analitycs State</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='flex flex-col gap-3'>
                                <div className='flex gap-3'>
                                    <div>Queued: </div>
                                    { (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AccountsAnalitycsEntities[0].PostingData!.AmountOfQueued :0}
                                </div>
                                <div className='flex gap-3'>
                                    <div>Cant Resolve: </div>
                                </div>
                                <div className='flex gap-3'>
                                    <div>Cant Join: </div>
                                    { (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AccountsAnalitycsEntities[0].PostingData!.AmountOfJoinFailed:0}
                                </div>
                                <div className='flex gap-3'>
                                    <div>Joined: </div>
                                    { (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AccountsAnalitycsEntities[0].PostingData!.AmountOfJoined:0}
                                </div>
                                <div className='flex gap-3'>
                                    <div>Already Joined: </div>
                                </div>
                                <div className='flex gap-3'>
                                    <div>Post Failed:</div>
                                    { (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AccountsAnalitycsEntities[0].PostingData!.AmountOfPostFailed:0}

                                </div>
                                <div className='flex gap-3'>
                                    <div>Posted:</div>
                                    { (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AccountsAnalitycsEntities[0].PostingData!.AmountOfPosted:0}
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                            <Typography> Account State</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='flex flex-col gap-3 max-h-[250px] overflow-y-auto'>
                                <div className='flex gap-3'> 
                                    <div>Program Start:{ (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AmountOfByStatesFlags.ProgramStart:0} </div>
                                </div>
                                <div className='flex gap-3'> 
                                    <div>Data Extracted: { (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AmountOfByStatesFlags.DataExtracted:0}</div>
                                </div>
                                <div className='flex gap-3'> 
                                    <div>Integrity Error: { (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AmountOfByStatesFlags.IntegrityError:0}</div>
                                </div>
                                <div className='flex gap-3'> 
                                    <div>Authorization: { (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AmountOfByStatesFlags.Authorization:0} </div>
                                </div>
                                <div className='flex gap-3'> 
                                    <div>Proxy Unavailable:{ (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AmountOfByStatesFlags.ProxyUnavailable:0} </div>
                                </div>
                                <div className='flex gap-3'> 
                                    <div>Logged In: { (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AmountOfByStatesFlags.LoggedIn:0}</div>
                                </div>
                                <div className='flex gap-3'> 
                                    <div>Deactivated:{ (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AmountOfByStatesFlags.Deactivated:0}</div>
                                </div>
                                <div className='flex gap-3'> 
                                    <div>Availability Check: { (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AmountOfByStatesFlags.AvailabilityCheck:0}</div>
                                </div>
                                <div className='flex gap-3'> 
                                    <div>Available:{ (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AmountOfByStatesFlags.Available:0}</div>
                                </div>
                                <div className='flex gap-3'> 
                                    <div>Not Available: { (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AmountOfByStatesFlags.NotAvailable:0} </div>
                                </div>
                                <div className='flex gap-3'> 
                                    <div>On Group Joining:{ (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AmountOfByStatesFlags.OnGroupJoining:0}</div>
                                </div>
                                <div className='flex gap-3'> 
                                    <div>On Posting:{ (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AmountOfByStatesFlags.OnPosting:0} </div>
                                </div>
                                <div className='flex gap-3'> 
                                    <div>Posted:{ (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AmountOfByStatesFlags.Posted:0}</div>
                                </div>
                                <div className='flex gap-3'> 
                                    <div>Disposed:{ (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AmountOfByStatesFlags.Disposed:0} </div>
                                </div>
                                <div className='flex gap-3'> 
                                    <div>On Proxy Connection:{ (Analytics.AccountsData !== undefined) ? Analytics.AccountsData.AmountOfByStatesFlags.OnProxyConnection:0} </div>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                            <Typography> Availability Flag</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='flex flex-col gap-3'>
                                <div className='flex gap-3'>
                                    <Switch/>
                                    <div>Good News </div>
                                </div>
                                <div className='flex gap-3'>
                                    <Switch/>
                                    <div>Temporary Ban </div>
                                </div>
                                <div className='flex gap-3'>
                                    <Switch/>
                                    <div>Reported </div>
                                </div>
                                <div className='flex gap-3'>
                                    <Switch/>
                                    <div>New Account </div>
                                </div>
                                <div className='flex gap-3'>
                                    <Switch/>
                                    <div>Reported For Spam </div>
                                </div>
                                <div className='flex gap-3'>
                                    <Switch/>
                                    <div>Indefined Reponse </div>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className='flex flex-col gap-4'>
                    <div className='font-bold text-[19px] text-[#333]'>
                        Розсилка
                    </div>
                    <div className='flex gap-10 '>
                        <button onClick={()=>dispatch(PostingTelegram(DefalutData))} className='bg-sky-400 text-white p-2 px-20 rounded '>
                            Default
                        </button>
                        <button onClick={()=>dispatch(PostingTelegram(ExtraData))}  className='bg-sky-400 text-white p-2 px-20 rounded'>
                            Extra
                        </button>
                    </div>
                    <form onSubmit={formik.handleSubmit} className='border border-solid border-neutral-400 p-5 rounded flex flex-col gap-4'>
                        <TextField name='AccountsCount' onChange={formik.handleChange} size='small' id="outlined-basic" label="Count Account" variant="outlined" />
                        <TextField name='SessionTask' onChange={formik.handleChange} size='small' id="outlined-basic" label="Task" variant="outlined" />
                    </form>
                </div>
                <div className='w-[360px] mx-10' >
                    <Accordion className='max-h-[600px]' expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                        <AccordionSummary  aria-controls="panel5d-content" id="panel5d-header">
                            Select Date from Analytics
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='flex flex-col gap-3 max-h-[350px] overflow-y-auto'>
                                {dates.length > 0  ? dates.map(i=>(
                                    <div className='cursor-pointer hover:bg-slate-100 p-3' onClick={()=> {
                                        dispatch(SetCurrentDate(i))
                                        handleChange('panel3')
                                    }}>
                                        {i}
                                    </div>
                                )):''}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </Box>
    )
}

